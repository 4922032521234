import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './CompanyLogo.module.css';
import logo from '../../../assets/images/logo.png';

function CompanyLogo() {
  const { t } = useTranslation();

  return (
    <div style={{marginLeft: '15px'}}>
      <NavLink to='/' className={styles.companyName}>
        <div className={styles.companyLogo}>
          <img src={logo} alt={t('sidebar.logo')} />
        </div>
      </NavLink>
    </div>
  );
}

export default CompanyLogo;
