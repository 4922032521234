import OutsideClickHandler from 'react-outside-click-handler';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './SelectCustomer.module.css';
import User from '../../../models/User';
import { initUser } from '../../../context/customer-context';
import arrow from '../../../assets/images/selectArrow.png';

type SelectProps = {
  options: User[],
  value?: User
  onUpdate: (value: any) => void
}

function SelectCustomer(props: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<User>(initUser);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const outsideClickHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const optionClickHandler = (optionValue: User) => {
    setValue(optionValue);
    props.onUpdate(optionValue);
    toggle();
  };

  return (
    <div className={styles.container}>
      <OutsideClickHandler onOutsideClick={outsideClickHandler} display='inline-block'>
        <div className={classNames(styles.selectInput, isOpen && styles.border)}>
          <div className={styles.selectedValue}>
            <span className={styles.value}>
              {value.firstName}
              {' '}
              {value.name}
            </span>
            <div
              role='button'
              onClick={toggle}
              tabIndex={0}
              onKeyDown={toggle}>
              <img
                src={arrow}
                alt='select arrow'
                className={isOpen ? styles.arrowUp : styles.arrowDown}
              />
            </div>
          </div>
          {isOpen && (
            <ul>
              {props.options.map(option =>
                <div
                  role='button'
                  key={option.registrationCode}
                  onClick={() => optionClickHandler(option)}
                   tabIndex={0}
                  onKeyDown={() => optionClickHandler(option)}
                >
                  <li>
                    {option.firstName}
                    {' '}
                    {option.name}
                  </li>
                </div>,
              )}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}

export default SelectCustomer;
