import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CompanyLogo from './CompanyLogo';
import styles from './Header.module.css';
import LanguagePicker from './LanguagePicker';
import SelectCustomer from '../../common/SelectCustomer/SelectCustomer';
import SelectAccount from '../../common/SelectAccount/SelectAccount';
import CustomerContext from '../../../context/customer-context';
import { performLogout } from '../../../services/login/loginService';
import APIError from '../../../services/APIError';
import UserContext from '../../../context/user-context';
import User from '../../../models/User';
import hamburgerIcon from '../../../assets/images/hamburger.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import EverisePortfolio from "../../../models/EverisePortfolio";
import { updateCustomerDefaultPortfolioPatch } from "../../../services/customer/customerService";

type HeaderProps = {
  toggleSidebar: () => void;
}

function Header(props: HeaderProps) {
  const { t } = useTranslation();
  const customerCtx = useContext(CustomerContext);
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 910px)');

  const handleLogout = async () => {
    try {
      await performLogout({});
      userCtx.setUser(undefined);
      customerCtx.setCustomers(undefined);
      userCtx.setIsLoggedIn(false)
      navigate('/login');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response);
          throw new APIError(error.response);
        }
      } else {
        throw error;
      }
    }
  };

  const updateDefaultPortfolioId = async (newDefaultPortfolioId: number) => {
    try {
      await updateCustomerDefaultPortfolioPatch({
        id: customerCtx.activeCustomer?.id,
        defaultPortfolioId: newDefaultPortfolioId
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response);
          throw new APIError(error.response);
        }
      } else {
        throw error;
      }
    }
  };

  const handleSelectCustomer = (value: User) => {
    customerCtx.setActiveCustomer(value);
  };

  const handleSelectAccount = (value: EverisePortfolio) => {
    if(customerCtx.activeCustomer && value) {
      const newActiveCustomer = {
        ...customerCtx?.activeCustomer,
        defaultPortfolioId: value.everisePortfolioId
      };
      customerCtx.setActiveCustomer(newActiveCustomer);
    }
    updateDefaultPortfolioId(value.everisePortfolioId);
  };

  return (
    <div className={styles.header}>
      {isMobile && (
        <div
          role='button'
          tabIndex={0}
          onClick={props.toggleSidebar}
          onKeyDown={props.toggleSidebar}
          className={styles.hamburger}
        >
          <img src={hamburgerIcon} alt='hamburger icon' />
        </div>
      )}
      {!isMobile && <CompanyLogo />}
      <div className={styles.userPanel}>
        {customerCtx.customers && (
          <>
            {customerCtx.customers.length === 1 && (
              <div className={styles.customerContainer}>
                <div className={styles.user}>
                  {customerCtx.customers[0].firstName}
                  {' '}
                  {customerCtx.customers[0].name}
                </div>
              </div>

            )}
            {customerCtx.customers.length > 1 && (
              <SelectCustomer
                options={customerCtx.customers}
                onUpdate={(value) => handleSelectCustomer(value)}
                value={customerCtx.activeCustomer || customerCtx.customers[0]}
              />
            )}
          </>
        )}
        {customerCtx.activeCustomer?.portfolios && (
          <div>
            {customerCtx.activeCustomer.portfolios.length > 1 && (
                <SelectAccount
                  options={customerCtx.activeCustomer?.portfolios || []}
                  onUpdate={(value) => handleSelectAccount(value)}
                  value={
                    customerCtx.activeCustomer?.defaultPortfolioId !== null
                    ? customerCtx.activeCustomer.defaultPortfolioId
                    : customerCtx.activeCustomer?.portfolios[0].everisePortfolioId
                  }
                />
              )}
          </div>
        )}
        <LanguagePicker />
        {userCtx.isLoggedIn && (
          <div
            role='button'
            tabIndex={0}
            className={styles.logOut}
            onClick={handleLogout}
            onKeyDown={handleLogout}
          >
            {t('header.logout')}
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
