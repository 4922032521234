export const formatNumber = (initNumber: number) => initNumber.toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const applyRoundingTwoDigits = (initNumber: number) =>
  (Math.round((initNumber + Number.EPSILON) * 100) / 100)
  .toFixed(2)
  .replace('.', ',')
  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const applyRoundingThreeDigits = (initNumber: number) =>
  (Math.round((initNumber + Number.EPSILON) * 1000) / 1000)
  .toFixed(3)
  .replace('.', ',')
  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
