import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './locales/common/translation.json'
import en from './locales/en/translation.json'
import et from './locales/et/translation.json'
import lv from './locales/lv/translation.json'
import lt from './locales/lt/translation.json'

const selectedLanguage = localStorage.getItem('lang');

i18n.use(initReactI18next).init({
  lng: selectedLanguage || 'en',
  debug: true,
  resources: {
    en: {
      translations: en,
      common
    },
    et: {
      translations: et,
      common
    },
    lv: {
      translations: lv,
      common
    },
    lt: {
      translations: lt,
      common
    }
  },
  ns: ['common', 'translations'],
  defaultNS: 'translations'
});

export default i18n;
