import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectLanguage from '../../common/SelectLanguage/SelectLanguage';

const supportedLanguages = [
  {value: 'en'},
  {value: 'et'},
  {value: 'lt'},
  {value: 'lv'}
];

function LanguagePicker() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('lang') || 'et');

  useEffect(() => {
    if(localStorage.getItem('lang')) {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      setSelectedLanguage(localStorage.getItem('lang')!)
    } else {
      setSelectedLanguage('et')
    }
  }, [])

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    setSelectedLanguage(lang);
  };

  return (
    <div>
      <SelectLanguage
        options={supportedLanguages}
        value={selectedLanguage}
        onUpdate={changeLanguage}
      />
    </div>
  )
}

export default LanguagePicker;
