import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerContext from '../../context/customer-context';
import APIError from '../../services/APIError';
import { retrievePortfolio } from '../../services/portfolio/portfolioService';
import styles from './DashboardPage.module.css';
import BondsSection from '../../components/dashboard/Bonds/BondsSection';
import CashSection from '../../components/dashboard/Cash/CashSection';
import Portfolio from '../../models/Portfolio';
import Spinner from '../../components/common/Spinner/Spinner';
import Error from '../../components/common/Error/Error';
import SharesSection from '../../components/dashboard/Shares/SharesSection';
import FundsSection from '../../components/dashboard/Funds/FundsSection';

function DashboardPage() {
  const { t } = useTranslation();
  const customerCtx = useContext(CustomerContext);
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getPortfolio = async () => {
    try {
      setIsLoading(true);
      if (customerCtx.activeCustomer && customerCtx.activeCustomer?.defaultPortfolioId) {
        const result = await retrievePortfolio(
          { portfolioId: customerCtx.activeCustomer.defaultPortfolioId }
        );
        setPortfolio(result);
      } else if (
        customerCtx.activeCustomer &&
        customerCtx.activeCustomer.defaultPortfolioId == null &&
        customerCtx.activeCustomer?.portfolios.length > 0
      ) {
        const result = await retrievePortfolio(
          { portfolioId: customerCtx.activeCustomer.portfolios[0].everisePortfolioId }
        );
        setPortfolio(result);
      } else if (customerCtx.activeCustomer && !customerCtx.activeCustomer.defaultPortfolioId) {
        setError(t('everise.portfolio.notAssigned'));
      }
      setIsLoading(false);
    } catch (err) {
      if (err instanceof APIError) {
        console.log(`Failed to retrieve customer portfolio: ${err.message}`);
        setError(t(`error.everise.${err.errorBody}`));
      } else {
        console.log(err);
        setError(t(`error.everise.other`));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPortfolio(undefined);
    setError('');
    getPortfolio();
  }, [customerCtx.activeCustomer]);

  return (
    <>
      <h2 className={styles.pageTitle}>{t('sidebar.pages.dashboard')}</h2>
      {error && (
        <div>
          <Error messageText={error} />
        </div>
      )}
      {portfolio && (
        <>
          <SharesSection shares={portfolio.shares} />
          <BondsSection bonds={portfolio.bonds} />
          <FundsSection funds={portfolio.funds} />
          <CashSection cash={portfolio.cash} />
        </>
      )}
      {isLoading && <Spinner />}
    </>
  );
}

export default DashboardPage;
