import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { getFrontEndConfig } from './services/config/configService';
import LoginPage from './pages/Login/LoginPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import RequireAuth from './components/auth/RequireAuth';
import Success from './pages/Login/Success';
import styles from './App.module.css';
import Header from './components/layouts/Header/Header';

function App() {
  const { i18n } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      const getDefaultLanguage = async () => {
        try {
          const frontEndConfig = await getFrontEndConfig();
          i18n.changeLanguage(frontEndConfig.defaultLanguage.toLowerCase());
        } catch (err) {
          i18n.changeLanguage('en');
        }
      };
      getDefaultLanguage();
    }
  }, [i18n]);

  const toggleSidebar = () => {
    setShowSidebar(prevState => !prevState);
  }

  return (
    <div>
      <Header toggleSidebar={toggleSidebar}/>
      <main className={styles.container}>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/success' element={<Success />} />
          <Route element={<RequireAuth showSidebar={showSidebar}/>}>
            <Route path='/' element={<DashboardPage />} />
          </Route>
        </Routes>
      </main>
    </div>
  );
}

export default App;
