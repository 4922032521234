import axios, { AxiosRequestConfig } from 'axios';
import APIError from './APIError';

const BASE_PATH = '/api'

const API = {
  async makeRequest(path: string, init: Record<string, unknown>, options: AxiosRequestConfig = {}) {
    try {
      const response = await axios({
        url: BASE_PATH + path,
        ...init,
        timeout: 20000,
        ...options
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response);
          throw new APIError(error.response);
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  },

  async makeGetRequest(
    path: string,
    queryParams: Record<string, unknown>,
    options: AxiosRequestConfig = {},
  ) {
    const init = {
      method: 'GET',
      params: queryParams,
    };
    return this.makeRequest(path, init, options);
  },

  async makePostRequest(
    path: string,
    body: Record<string, unknown>,
    options: AxiosRequestConfig = {},
  ) {
    const init = {
      method: 'POST',
      data: body,
    };
    return this.makeRequest(path, init, options);
  },

  async makePatchRequest(
    path: string,
    body: Record<string, unknown>,
    queryParams: Record<string, unknown>,
    options: AxiosRequestConfig = {},
  ) {
    const init = {
      method: 'PATCH',
      data: body,
      params: queryParams,
    };
    return this.makeRequest(path, init, options);
  },

  async makePutRequest(
    path: string,
    body: Record<string, unknown>,
    options: AxiosRequestConfig = {},
  ) {
    const init = {
      method: 'PUT',
      data: body,
    };
    return this.makeRequest(path, init, options);
  },

  async makeDeleteRequest(
    path: string,
    body: Record<string, unknown>,
    options: AxiosRequestConfig = {},
  ) {
    const init = {
      method: 'DELETE',
      data: body,
    };
    return this.makeRequest(path, init, options);
  },
}

export default API;
