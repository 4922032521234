import { AxiosRequestConfig } from 'axios';
import API from '../API';

export const getLoginUrl = (
  queryParams: Record<string, unknown> = {},
  options: AxiosRequestConfig = {},
): Promise<any> => API.makeGetRequest(`/login-info`, queryParams, options) as Promise<any>;

export const retrieveUserInfo = (
  queryParams: Record<string, unknown> = {},
  options: AxiosRequestConfig = {},
): Promise<string> => API.makeGetRequest(`/user`, queryParams, options) as Promise<string>;

export const performLogout = (
  body: Partial<any>,
  options: AxiosRequestConfig = {},
): Promise<any> => API.makePostRequest(`/logout`, body, options)