import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import Cash from '../../../models/Cash';
import { applyRoundingTwoDigits } from '../util';
import SectionWrapper from '../SectionWrapper';
import TableWrapper from '../TableWrapper';
import styles from '../common.module.css';
import useMediaQuery from '../../../hooks/useMediaQuery';
import CashRow from './CashRow';

type CashSectionProps = {
  cash: Cash[];
}

function CashSection(props: CashSectionProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 549px)')

  const tableHeaders = [
    t('everise.portfolio.cash.table.header.ccy'),
    t('everise.portfolio.cash.table.header.fxRate'),
    t('everise.portfolio.cash.table.header.balance'),
    t('everise.portfolio.cash.table.header.reserved'),
    t('everise.portfolio.cash.table.header.availableBalance'),
    t('everise.portfolio.cash.table.header.availableBalanceBase'),
  ];

  const mobileHeaders = [
    t('everise.portfolio.cash.table.header.ccy'),
    t('everise.portfolio.cash.table.header.balance'),
    t('everise.portfolio.cash.table.header.availableBalanceBase'),
  ]

  return (
    <>
      {props.cash.length > 0 && (
        <SectionWrapper title={t('everise.portfolio.cash.title')}>
          <TableWrapper
            headers={isMobile ? mobileHeaders : tableHeaders}
            isExpandable={isMobile}
            numberHeaderStartIndex={1}
          >
            {props.cash.sort((a, b) => a.ccy > b.ccy ? 1 : -1).map(cash =>
              <CashRow cash={cash} key={uuid()}/>
            )}
            <tr className={styles.totalRow}>
              <td className={styles.totalTitle}>{t('everise.portfolio.total')}</td>
              {!isMobile && <td>&nbsp;</td>}
              <td>&nbsp;</td>
              {!isMobile && <td>&nbsp;</td>}
              {!isMobile && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.cash.reduce((accumulator, cash) =>
                  accumulator + cash.availableBalanceBase, 0))
                }
              </td>
            </tr>
          </TableWrapper>
        </SectionWrapper>
      )}
      {props.cash.length === 0 && (
        <div className={styles.noData}>{t('everise.portfolio.cash.noData')}</div>
      )}
    </>

  );
}

export default CashSection;
