import classNames from 'classnames';
import styles from './Button.module.css';

type ButtonProps = {
  handleClick: () => void;
  text: string
  outline?: boolean
  width?: string
  height?: string
  margin?: string
}

function Button(props: ButtonProps) {
  return (
    <button
      type='button'
      className={classNames(styles.button, props.outline ? styles.outline : styles.default)}
      onClick={props.handleClick}
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      {props.text}
    </button>
  );
}

export default Button;
