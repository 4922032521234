import { ReactNode } from 'react';
import uuid from 'react-uuid';
import classNames from 'classnames';
import styles from './TableWrapper.module.css';

type TableWrapperProps = {
  headers: string[],
  isExpandable: boolean,
  children: ReactNode,
  numberHeaderStartIndex: number
}

function TableWrapper(props: TableWrapperProps) {
  return (
    <table className={styles.table}>
      <thead>
      <tr>
        {props.headers.map((header, index) =>
          <th
            key={uuid()}
            className={classNames(styles.header, index >= props.numberHeaderStartIndex
              ? styles.numberHeader
              : styles.textHeader)}
          >
            {header}
          </th>,
        )}
        {props.isExpandable && (
          <th className={styles.header}>&nbsp;</th>
        )}
      </tr>
      </thead>
      <tbody>
      {props.children}
      </tbody>
    </table>
  );
}

export default TableWrapper;