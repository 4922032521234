import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Bond from '../../../models/Bond';
import { applyRoundingTwoDigits, formatNumber } from '../util';
import expand from '../../../assets/images/expand.png';
import collapse from '../../../assets/images/collapse.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import styles from '../common.module.css';

type BondRowProps = {
  bond: Bond
}

function BondRow(props: BondRowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 850px)');
  const { t } = useTranslation();

  const toggleExpandButton = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <>
      <tr className={classNames(
        isExpanded ? styles.row : isDesktop ? styles.border : styles.radiusBorder
      )}>
        <td className={styles.description}>{props.bond.description}</td>
        {isDesktop && <td className={styles.column}>{props.bond.ccy}</td>}
        {isDesktop && <td className={styles.numberColumn}>
          {props.bond.amount !== null ? formatNumber(props.bond.amount) : '-'}
        </td>}
        {isDesktop && <td className={styles.numberColumn}>
          {props.bond.price !== null ? applyRoundingTwoDigits(props.bond.price) : '-'}
        </td>}
        {isDesktop && <td className={styles.numberColumn}>
          {props.bond.accruedInterest !== null
            ? applyRoundingTwoDigits(props.bond.accruedInterest)
            : '-'
          }
        </td>}
        <td className={styles.numberColumn}>
          {props.bond.change !== null
            ? `${applyRoundingTwoDigits(props.bond.change * 100)}%`
            : '-'
          }
        </td>
        <td className={styles.numberColumn}>
          {props.bond.profitLossBase !== null
            ? applyRoundingTwoDigits(props.bond.profitLossBase)
            : '-'
          }
        </td>
        {isDesktop && <td className={styles.numberColumn}>
          {props.bond.value !== null ? applyRoundingTwoDigits(props.bond.value) : '-'}
        </td>}
        <td className={styles.numberColumn}>
          {props.bond.valueBase !== null ? applyRoundingTwoDigits(props.bond.valueBase) : '-'}
        </td>
        {!isDesktop && <td className={styles.expandableColumn}>
          <div
            role='button'
            onClick={toggleExpandButton}
            onKeyDown={toggleExpandButton}
            className={styles.arrow}
            tabIndex={0}
          >
            {!isExpanded && <img src={expand} alt='expand arrow' />}
            {isExpanded && <img src={collapse} alt='collapse arrow' />}
          </div>
        </td>}
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={6} className={styles.expandedTd}>
            <div className={styles.expandedSection}>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading} style={{textAlign: 'left'}}>
                  {t('everise.portfolio.bonds.table.header.ccy')}
                </p>
                <p>{props.bond.ccy}</p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.bonds.table.header.amount')}
                </p>
                <p className={styles.expandedValue}>
                  {props.bond.amount !== null ? formatNumber(props.bond.amount) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.bonds.table.header.price')}
                </p>
                <p className={styles.expandedValue}>
                  {props.bond.price !== null ? applyRoundingTwoDigits(props.bond.price) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.bonds.table.header.accruedInterest')}
                </p>
                <p className={styles.expandedValue}>
                  {props.bond.accruedInterest !== null
                    ? applyRoundingTwoDigits(props.bond.accruedInterest)
                    : '-'
                  }
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.bonds.table.header.value')}
                </p>
                <p className={styles.expandedValue}>
                  {props.bond.value !== null ? applyRoundingTwoDigits(props.bond.value) : '-'}
                </p>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>

  );
}

export default BondRow;
