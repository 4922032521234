import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './Sidebar.module.css';
import dashboardIcon from '../../../assets/images/dashboard.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import CompanyLogo from '../Header/CompanyLogo';

type SidebarProps = {
  showSidebar?: boolean
}

function Sidebar(props: SidebarProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 910px)');

  return (
    <aside className={!isMobile ? styles.container : classNames(
      props.showSidebar ? styles.showContainer : styles.container, styles.animation,
    )}>
      {isMobile && <CompanyLogo />}
      <div className={styles.navigation}>
        <NavLink to='/' className={({ isActive }) => isActive
          ? classNames(styles.navLink, styles.activeLink)
          : styles.navLink}
        >
          <div className={styles.circle}>
            <img className={styles.icon} src={dashboardIcon} alt='' />
          </div>
          <span className={styles.text}>{t('sidebar.pages.dashboard')}</span>
        </NavLink>
        {/*<NavLink to='/reports' className={({ isActive }) => isActive*/}
        {/*  ? classNames(styles.navLink, styles.activeLink)*/}
        {/*  : styles.navLink}*/}
        {/*>*/}
        {/*  <div className={styles.circle}>*/}
        {/*    <img className={styles.icon} src={reportsIcon} alt='' />*/}
        {/*  </div>*/}
        {/*  <span className={styles.text}>{t('sidebar.pages.reports')}</span>*/}
        {/*</NavLink>*/}
        {/*<NavLink to='/money-transfer' className={({ isActive }) => isActive*/}
        {/*  ? classNames(styles.navLink, styles.activeLink)*/}
        {/*  : styles.navLink}*/}
        {/*>*/}
        {/*  <div className={styles.circle}>*/}
        {/*    <img className={styles.icon} src={moneyTransfersIcon} alt='' />*/}
        {/*  </div>*/}
        {/*  <span className={styles.text}>{t('sidebar.pages.moneyTransfers')}</span>*/}
        {/*</NavLink>*/}
        {/*<NavLink to='/role-selection' className={({ isActive }) => isActive*/}
        {/*  ? classNames(styles.navLink, styles.activeLink)*/}
        {/*  : styles.navLink}*/}
        {/*>*/}
        {/*  <div className={styles.circle}>*/}
        {/*    <img className={styles.icon} src={roleSelectionIcon} alt='' />*/}
        {/*  </div>*/}
        {/*  <span className={styles.text}>{t('sidebar.pages.roleSelection')}</span>*/}
        {/*</NavLink>*/}
        {/*<NavLink to='/settings' className={({ isActive }) => isActive*/}
        {/*  ? classNames(styles.navLink, styles.activeLink)*/}
        {/*  : styles.navLink}*/}
        {/*>*/}
        {/*  <div className={styles.circle}>*/}
        {/*    <img className={styles.icon} src={settingsIcon} alt='' />*/}
        {/*  </div>*/}
        {/*  <span className={styles.text}>{t('sidebar.pages.settings')}</span>*/}
        {/*</NavLink>*/}
      </div>
    </aside>
  );
}

export default Sidebar;
