import { AxiosRequestConfig } from 'axios';
import API from '../API';
import User from '../../models/User';

export const retrieveRelatedCustomers = (
  queryParams: Record<string, unknown> = {},
  options: AxiosRequestConfig = {},
): Promise<User[]> => API.makeGetRequest(`/customer`, queryParams, options) as Promise<User[]>;

// instead of post request use patch to update customer default portfolio id
export const updateCustomerDefaultPortfolioPatch = (
  body: Partial<any>,
  queryParams: Partial<any> = {},
): Promise<any> => API.makePatchRequest(`/customer`, body, queryParams)
