import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import UserContext from '../../context/user-context';
import APIError from '../../services/APIError';
import { retrieveUserInfo } from '../../services/login/loginService';
import { retrieveCurrentUser } from '../../services/user/userService';

function Success() {
  const userCtx = useContext(UserContext);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const retrieveInfo = async () => {
    try {
      await retrieveUserInfo({
        returnToken: searchParams.get('session_token'),
        loginName: localStorage.getItem('loginName')
      });
      const userInfo = await retrieveCurrentUser();
      userCtx.setUser(userInfo);
      userCtx.setIsLoggedIn(true);
    } catch (error) {
      if (error instanceof APIError) {
        console.log(`Failed to retrieve user info: ${error.message}`);
      } else {
        console.log(error);
      }
      userCtx.setUser(undefined);
      userCtx.setIsLoggedIn(false);
      if (localStorage.getItem('loginName')) {
        userCtx.setIsAuthenticationFailed(true);
      }
    }
    navigate('/');
  };

  useEffect(() => {
    retrieveInfo();
  }, []);

  return (
    <div />
  );
}

export default Success;
