import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../layouts/Sidebar/Sidebar';
import styles from './RequireAuth.module.css';
import APIError from '../../services/APIError';
import { retrieveCurrentUser } from '../../services/user/userService';
import UserContext from '../../context/user-context';
import { retrieveRelatedCustomers } from '../../services/customer/customerService';
import CustomerContext from '../../context/customer-context';

type RequireAuthProps = {
  showSidebar: boolean
}

function RequireAuth(props: RequireAuthProps) {
  const userCtx = useContext(UserContext);
  const customerCtx = useContext(CustomerContext);
  const navigate = useNavigate();

  const getUserInfo = async () => {
    try {
      const userInfo = await retrieveCurrentUser();
      userCtx.setUser(userInfo);
      userCtx.setIsLoggedIn(true);
    } catch (error) {
      if (error instanceof APIError) {
        console.log(`Failed to retrieve user info: ${error.message}`);
      } else {
        console.log(error);
      }
      userCtx.setUser(undefined);
      userCtx.setIsLoggedIn(false);
      if(localStorage.getItem('loginName')) {
        userCtx.setIsAuthenticationFailed(true);
      }
      navigate('/login')
    }
  };

  const getRelatedUsers = async () => {
    try {
      const relatedUsers = await retrieveRelatedCustomers(
        { registrationCode: userCtx.user.registrationCode },
      );
      customerCtx.setCustomers(relatedUsers);
      customerCtx.setActiveCustomer(relatedUsers[0]);
    } catch (error) {
      if (error instanceof APIError) {
        console.log(`Failed to retrieve related customers: ${error.message}`);
      } else {
        console.log(error);
      }
      customerCtx.setCustomers(undefined);
      customerCtx.setActiveCustomer(undefined);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userCtx.user && userCtx.user.registrationCode) {
      getRelatedUsers();
    }
  }, [userCtx.user]);

  return (
    <div className={styles.container}>
      <Sidebar showSidebar={props.showSidebar}/>
      <div style={{width: '100%'}}>
        <Outlet />
      </div>
    </div>
  );

}

export default RequireAuth;
