import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user-context';
import { getLoginUrl } from '../../services/login/loginService';
import APIError from '../../services/APIError';
import styles from './LoginPage.module.css';
import Input from '../../components/common/Input/Input';
import Button from '../../components/common/Button/Button';
import Error from '../../components/common/Error/Error';

function LoginPage() {
  const { t } = useTranslation();
  const userCtx = useContext(UserContext);
  const [loginName, setLoginName] = useState('');
  const [loginNameError, setLoginNameError] = useState(false);
  const [loginUrl, setLoginUrl] = useState<string>();
  const [loginUrlError, setLoginUrlError] = useState(false);

  useEffect(() => {
    if (!loginUrlError && loginUrl) {
      window.location.href = loginUrl;
    }
  }, [loginUrl, loginUrlError]);

  useEffect(() => {
    localStorage.removeItem('loginName');
  }, []);

  const handleInputChange = (value: string) => {
    setLoginName(value);
  };

  const handleLoginButton = async () => {
    setLoginNameError(false);
    const locale = localStorage.getItem('lang');
    if (loginName !== '') {
      localStorage.setItem('loginName', loginName);
      try {
        const response = await getLoginUrl(locale ? { locale } : { locale: '' });
        setLoginUrl(`${response.login_path}`);
      } catch (err) {
        if (err instanceof APIError) {
          console.log(`Failed to retrieve login url: ${err.message}`);
        } else {
          console.log(err);
        }
        setLoginUrlError(true);
      }
    } else {
      setLoginNameError(true);
    }
  };

  return (
    <div className={styles.container}>
      {userCtx.isAuthenticationFailed && (
        <div>
          <Error messageText={t('error.authenticationFailed')} />
        </div>
      )}
      <h2 className={styles.title}>{t('login.title')}</h2>
      <div className={styles.input}>
        <Input
          id='login'
          placeholder={t('login.input.placeholder')}
          type='text'
          label={t('login.input.label')}
          required
          value={loginName}
          error={loginNameError}
          errorMsg={t('error.loginName')}
          onUpdate={(value) => handleInputChange(value)}
        />
        <div className={styles.button}>
          <Button
            handleClick={handleLoginButton}
            text={t('login.loginButton')}
            width='100%'
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
