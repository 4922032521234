import OutsideClickHandler from 'react-outside-click-handler';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './SelectLanguage.module.css';
import arrow from '../../../assets/images/selectArrow.png';

type SelectLanguageProps = {
  options: {
    value: string
  }[],
  value: string
  onUpdate: (value: any) => void
}

function SelectLanguage(props: SelectLanguageProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<string>(props.value);

  const outsideClickHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const toggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const optionClickHandler = (optionValue: string) => {
    setValue(optionValue);
    props.onUpdate(optionValue);
    toggle();
  };

  const getLangName = (lang: string) => {
    switch (lang) {
      case 'en':
        return t('languages.en', { ns: 'common' });
      case 'lt':
        return t('languages.lt', { ns: 'common' });
      case 'lv':
        return t('languages.lv', { ns: 'common' });
      default:
        return t('languages.et', { ns: 'common' });
    }
  };

  return (
    <div className={styles.container}>
      <OutsideClickHandler onOutsideClick={outsideClickHandler}>
        <div className={classNames(styles.selectInput, isOpen && styles.border)}>
          <div
            role='button'
            onClick={toggle}
            className={styles.selectedValue}
            tabIndex={0}
            onKeyDown={toggle}
          >
            <span className={styles.value}>
              {getLangName(value)}
            </span>
            <img
              src={arrow}
              alt='select arrow'
              className={isOpen ? styles.arrowUp : styles.arrowDown}
            />
          </div>
          {isOpen && (
            <ul>
              {props.options.map(option =>
                <div role='button'
                     key={option.value}
                     onClick={() => optionClickHandler(option.value)}
                     tabIndex={0}
                     onKeyDown={() => optionClickHandler(option.value)}
                >
                  <li>
                    {getLangName(option.value)}
                  </li>
                </div>,
              )}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}

export default SelectLanguage;
