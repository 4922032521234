import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './Input.module.css';

type InputProps = {
  id: string
  placeholder: string
  type: string
  label?: string
  required?: boolean
  error?: boolean
  errorMsg?: string
  min?: number
  max?: number
  maxLength?: number
  value?: string
  width?: string
  onUpdate: (value: string) => void
}

function Input(props: InputProps) {
  const { t } = useTranslation();

  const changeHandler = (event: any) => {
    event.preventDefault();
    props.onUpdate(event.target.value);
  };

  return (
    <>
      <div
        className={styles.container}
        style={{ width: props.width }}
      >
        {props.label &&
          <label htmlFor={props.id} className={styles.label}>
            <span>{props.label}</span>
            {props.required && <span className={styles.required}>*</span>}
          </label>
        }
        <input
          id={props.id}
          className={classNames(styles.input, { [styles.error]: props.error })}
          placeholder={props.placeholder}
          type={props.type}
          onChange={changeHandler}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          value={props.value}
        />
      </div>
      {props.error &&
        <p className={styles.errorMessage} data-cy='inputError'>
          {props.errorMsg ? props.errorMsg : t('common.mandatory')}
        </p>
      }
    </>
  );
}

export default Input;
