import { AxiosResponse } from 'axios';

interface ServerErrorPayload {
  timestamp: string;
  status: number;
  error: string;
  exception: string;
  message: string;
  path: string;
}

class APIError extends Error {
  status: number;
  ok: boolean;
  errorBody: string;

  constructor(response: AxiosResponse) {
    const errorMessage =
      response.statusText ||
      `API request ${response.config.url} failed with status ${response.status}`;

    super(errorMessage);

    this.ok = false;
    this.status = response.status;
    this.errorBody = (response.data as ServerErrorPayload).message || response.statusText;
  }
}

export default APIError;
