import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import User from '../models/User';

export const initUser: User = {
  id: 0,
  firstName: '',
  name: '',
  registrationCode: '',
  country: '',
  everisePortfolioId: 0, //@TODO: remove this
  defaultPortfolioId: 0,
  portfolios: []
};

type CustomerContextType = {
  customers: User[];
  setCustomers: Dispatch<SetStateAction<any>>;
  activeCustomer: User | undefined;
  setActiveCustomer: Dispatch<SetStateAction<any>>;
}

const CustomerContext = createContext<CustomerContextType>({
  customers: [],
  setCustomers: () => undefined,
  activeCustomer: undefined,
  setActiveCustomer: () => undefined,
});

export function CustomerContextProvider(props: { children: ReactNode }) {
  const [customers, setCustomers] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState(undefined);

  const providerValue = useMemo(() => ({
    customers, setCustomers, activeCustomer, setActiveCustomer
  }), [customers, activeCustomer]);

  return (
    <CustomerContext.Provider value={providerValue}>
      {props.children}
    </CustomerContext.Provider>
  );
}

export default CustomerContext;