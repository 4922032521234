import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import Fund from '../../../models/Fund';
import styles from '../common.module.css';
import useMediaQuery from '../../../hooks/useMediaQuery';
import SectionWrapper from '../SectionWrapper';
import TableWrapper from '../TableWrapper';
import { applyRoundingTwoDigits } from '../util';
import FundRow from './FundRow';

type FundsSectionProps = {
  funds: Fund[]
}

function FundsSection(props: FundsSectionProps) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 850px)');

  const desktopHeaders = [
    t('everise.portfolio.table.header.description'),
    t('everise.portfolio.table.header.ccy'),
    t('everise.portfolio.table.header.amount'),
    t('everise.portfolio.table.header.cost'),
    t('everise.portfolio.table.header.price'),
    t('everise.portfolio.table.header.change'),
    t('everise.portfolio.table.header.profitLossBase'),
    t('everise.portfolio.table.header.value'),
    t('everise.portfolio.table.header.valueBase'),
  ];

  const mobileHeaders = [
    t('everise.portfolio.table.header.description'),
    t('everise.portfolio.table.header.change'),
    t('everise.portfolio.table.header.profitLossBase'),
    t('everise.portfolio.table.header.valueBase'),
  ];

  const calculateChangeTotal = () => {
    const profitLossBaseSum = props.funds.reduce((accumulator, bond) =>
      bond.profitLossBase !== null ? accumulator + bond.profitLossBase : accumulator, 0
    );
    const costBaseSum = props.funds.reduce((accumulator, bond) =>
      bond.costBase !== undefined ? accumulator + bond.costBase : accumulator, 0
    );
    return (profitLossBaseSum / costBaseSum) * 100;
  };

  return (
    <>
      {props.funds.length > 0 && (
        <SectionWrapper title={t('everise.portfolio.funds.title')}>
          <TableWrapper
            headers={isDesktop ? desktopHeaders : mobileHeaders}
            isExpandable={!isDesktop}
            numberHeaderStartIndex={isDesktop ? 2 : 1}
          >
            {props.funds.sort((a, b) => a.description > b.description ? 1 : -1).map(fund =>
              <FundRow fund={fund} key={uuid()} />
            )}
            <tr className={styles.totalRow}>
              <td className={styles.totalTitle}>{t('everise.portfolio.total')}</td>
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {`${applyRoundingTwoDigits(calculateChangeTotal())}%`}
              </td>
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.funds.reduce((accumulator, fund) =>
                  accumulator + fund.profitLossBase, 0
                ))}
              </td>
              {isDesktop && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.funds.reduce((accumulator, fund) =>
                  fund.valueBase ? accumulator + fund.valueBase : accumulator, 0
                ))}
              </td>
            </tr>
          </TableWrapper>
        </SectionWrapper>
      )}
      {props.funds.length === 0 && (
        <div className={styles.noData}>{t('everise.portfolio.funds.noData')}</div>
      )}
    </>
  );
}

export default FundsSection;
