import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import Bond from '../../../models/Bond';
import { applyRoundingTwoDigits } from '../util';
import SectionWrapper from '../SectionWrapper';
import TableWrapper from '../TableWrapper';
import styles from '../common.module.css';
import useMediaQuery from '../../../hooks/useMediaQuery';
import BondRow from './BondRow';

type BondsSectionProps = {
  bonds: Bond[];
}

function BondsSection(props: BondsSectionProps) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 850px)');

  const calculateChangeTotal = () => {
    const profitLossBaseSum = props.bonds.reduce((accumulator, bond) =>
      bond.profitLossBase !== null
        ? accumulator + bond.profitLossBase
        : accumulator, 0
    );
    const costBaseSum = props.bonds.reduce((accumulator, bond) =>
      bond.costBase !== undefined
        ? accumulator + bond.costBase
        : accumulator, 0
    );
    return (profitLossBaseSum / costBaseSum) * 100;
  };

  const desktopHeaders = [
    t('everise.portfolio.bonds.table.header.description'),
    t('everise.portfolio.bonds.table.header.ccy'),
    t('everise.portfolio.bonds.table.header.amount'),
    t('everise.portfolio.bonds.table.header.price'),
    t('everise.portfolio.bonds.table.header.accruedInterest'),
    t('everise.portfolio.bonds.table.header.change'),
    t('everise.portfolio.bonds.table.header.profitLossBase'),
    t('everise.portfolio.bonds.table.header.value'),
    t('everise.portfolio.bonds.table.header.valueBase'),
  ];

  const mobileHeaders = [
    t('everise.portfolio.bonds.table.header.description'),
    t('everise.portfolio.bonds.table.header.change'),
    t('everise.portfolio.bonds.table.header.profitLossBase'),
    t('everise.portfolio.bonds.table.header.valueBase'),
  ];

  return (
    <>
      {props.bonds.length > 0 && (
        <SectionWrapper title={t('everise.portfolio.bonds.title')}>
          <TableWrapper
            headers={isDesktop ? desktopHeaders : mobileHeaders}
            isExpandable={!isDesktop}
            numberHeaderStartIndex={isDesktop ? 2 : 1}
          >
            {props.bonds.sort((a, b) => a.description > b.description ? 1 : -1).map(bond =>
              <BondRow bond={bond} key={uuid()} />
            )}
            <tr className={styles.totalRow}>
              <td className={styles.totalTitle}>{t('everise.portfolio.total')}</td>
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {`${applyRoundingTwoDigits(calculateChangeTotal())}%`}
              </td>
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.bonds.reduce((accumulator, bond) =>
                  accumulator + bond.profitLossBase, 0
                ))}
              </td>
              {isDesktop && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.bonds.reduce((accumulator, bond) =>
                  bond.valueBase ? accumulator + bond.valueBase : accumulator, 0
                ))}
              </td>
            </tr>
          </TableWrapper>
        </SectionWrapper>
      )}
      {props.bonds.length === 0 && (
        <div className={styles.noData}>{t('everise.portfolio.bonds.noData')}</div>
      )}
    </>

  );
}

export default BondsSection;
