import styles from './Error.module.css';

type ErrorProps = {
  messageText: string
  logoSrc?: string
  logoAltText?: string
  outlined?: boolean
}

function Error(props: ErrorProps) {
  return (
    <div className={props.outlined ? styles.outlined : styles.container} data-cy='errorMsg'>
      {props.logoSrc && <img src={props.logoSrc} alt={props.logoAltText} className={styles.logo}/>}
      <p className={styles.text}>{props.messageText}</p>
    </div>
  )
}

export default Error;
