import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Fund from '../../../models/Fund';
import styles from '../common.module.css';
import { applyRoundingTwoDigits, formatNumber } from '../util';
import expand from '../../../assets/images/expand.png';
import collapse from '../../../assets/images/collapse.png';

type FundRowProps = {
  fund: Fund;
}

function FundRow(props: FundRowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 850px)');
  const { t } = useTranslation();

  const toggleExpandButton = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <>
      <tr className={
        classNames(isExpanded ? styles.row : isDesktop ? styles.border : styles.radiusBorder)
      }>
        <td className={styles.description}>{props.fund.description}</td>
        {isDesktop && <td className={styles.column}>{props.fund.ccy}</td>}
        {isDesktop && <td className={styles.numberColumn}>
          {props.fund.amount !== null ? formatNumber(props.fund.amount) : '-'}
        </td>}
        {isDesktop && <td className={styles.numberColumn}>
          {props.fund.cost !== null ? applyRoundingTwoDigits(props.fund.cost) : '-'}
        </td>}
        {isDesktop && <td className={styles.numberColumn}>
          {props.fund.price !== null ? applyRoundingTwoDigits(props.fund.price) : '-'}
        </td>}
        <td className={styles.numberColumn}>
          {props.fund.change !== null
            ? `${applyRoundingTwoDigits(props.fund.change * 100)}%`
            : '-'
          }
        </td>
        <td className={styles.numberColumn}>
          {props.fund.profitLossBase !== null
            ? applyRoundingTwoDigits(props.fund.profitLossBase)
            : '-'
          }
        </td>
        {isDesktop && <td className={styles.numberColumn}>
          {props.fund.value !== null ? applyRoundingTwoDigits(props.fund.value) : '-'}
        </td>}
        <td className={styles.numberColumn}>
          {props.fund.valueBase !== null ? applyRoundingTwoDigits(props.fund.valueBase) : '-'}
        </td>
        {!isDesktop && <td className={styles.expandableColumn}>
          <div
            role='button'
            tabIndex={0}
            onClick={toggleExpandButton}
            onKeyDown={toggleExpandButton}
            className={styles.arrow}
          >
            {!isExpanded && <img src={expand} alt='expand arrow' />}
            {isExpanded && <img src={collapse} alt='collapse arrow' />}
          </div>
        </td>}
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={6} className={styles.expandedTd}>
            <div className={styles.expandedSection}>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading} style={{textAlign: 'left'}}>
                  {t('everise.portfolio.table.header.ccy')}
                </p>
                <p>{props.fund.ccy}</p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.table.header.amount')}
                </p>
                <p className={styles.expandedValue}>
                  {props.fund.amount !== null ? formatNumber(props.fund.amount) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.table.header.cost')}
                </p>
                <p className={styles.expandedValue}>
                  {props.fund.cost !== null ? applyRoundingTwoDigits(props.fund.cost) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.table.header.price')}
                </p>
                <p className={styles.expandedValue}>
                  {props.fund.price !== null ? applyRoundingTwoDigits(props.fund.price) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.table.header.value')}
                </p>
                <p className={styles.expandedValue}>
                  {props.fund.value !== null ? applyRoundingTwoDigits(props.fund.value) : '-'}
                </p>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default FundRow;
