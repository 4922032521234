import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import Share from '../../../models/Share';
import styles from '../common.module.css';
import SectionWrapper from '../SectionWrapper';
import TableWrapper from '../TableWrapper';
import useMediaQuery from '../../../hooks/useMediaQuery';
import ShareRow from './ShareRow';
import { applyRoundingTwoDigits } from '../util';

type SharesSectionProps = {
  shares: Share[]
}

function SharesSection(props: SharesSectionProps) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 850px)');

  const desktopHeaders = [
    t('everise.portfolio.table.header.description'),
    t('everise.portfolio.table.header.ccy'),
    t('everise.portfolio.table.header.amount'),
    t('everise.portfolio.table.header.cost'),
    t('everise.portfolio.table.header.price'),
    t('everise.portfolio.table.header.change'),
    t('everise.portfolio.table.header.profitLossBase'),
    t('everise.portfolio.table.header.value'),
    t('everise.portfolio.table.header.valueBase'),
  ];

  const mobileHeaders = [
    t('everise.portfolio.table.header.description'),
    t('everise.portfolio.table.header.change'),
    t('everise.portfolio.table.header.profitLossBase'),
    t('everise.portfolio.table.header.valueBase'),
  ];


  const calculateChangeTotal = () => {
    const profitLossBaseSum = props.shares.reduce((accumulator, bond) =>
      bond.profitLossBase !== null ? accumulator + bond.profitLossBase : accumulator, 0
    );
    const costBaseSum = props.shares.reduce((accumulator, bond) =>
      bond.costBase !== undefined ? accumulator + bond.costBase : accumulator, 0
    );
    return (profitLossBaseSum / costBaseSum) * 100;
  };

  return (
    <>
      {props.shares.length > 0 && (
        <SectionWrapper title={t('everise.portfolio.shares.title')}>
          <TableWrapper
            headers={isDesktop ? desktopHeaders : mobileHeaders}
            isExpandable={!isDesktop}
            numberHeaderStartIndex={isDesktop ? 2 : 1}
          >
            {props.shares.sort((a, b) => a.description > b.description ? 1 : -1).map(share =>
              <ShareRow share={share} key={uuid()} />
            )}
            <tr className={styles.totalRow}>
              <td className={styles.totalTitle}>{t('everise.portfolio.total')}</td>
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              {isDesktop && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {`${applyRoundingTwoDigits(calculateChangeTotal())}%`}
              </td>
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.shares.reduce((accumulator, share) =>
                  accumulator + share.profitLossBase, 0
                ))}
              </td>
              {isDesktop && <td>&nbsp;</td>}
              <td className={styles.totalNumber}>
                {applyRoundingTwoDigits(props.shares.reduce((accumulator, share) =>
                  share.valueBase ? accumulator + share.valueBase : accumulator, 0
                ))}
              </td>
            </tr>
          </TableWrapper>
        </SectionWrapper>
      )}
      {props.shares.length === 0 && (
        <div className={styles.noData}>{t('everise.portfolio.shares.noData')}</div>
      )}
    </>
  );
}

export default SharesSection;
