import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Cash from '../../../models/Cash';
import useMediaQuery from '../../../hooks/useMediaQuery';
import styles from '../common.module.css';
import { applyRoundingThreeDigits, applyRoundingTwoDigits, formatNumber } from '../util';
import expand from '../../../assets/images/expand.png';
import collapse from '../../../assets/images/collapse.png';

type CashRowProps = {
  cash: Cash
}

function CashRow(props: CashRowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 850px)');
  const isMobile = useMediaQuery('(max-width: 549px)');
  const { t } = useTranslation();

  const toggleExpandButton = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <>
      <tr className={
        classNames(isExpanded ? styles.row : isDesktop ? styles.border : styles.radiusBorder)
      }>
        <td className={styles.column} style={{width: '15%'}}>{props.cash.ccy}</td>
        {!isMobile && <td className={styles.numberColumn}>
          {applyRoundingThreeDigits(props.cash.fxRate)}
        </td>}
        <td className={styles.numberColumn} style={{width: '15%'}}>
          {props.cash.balance !== null ? applyRoundingTwoDigits(props.cash.balance) : '-'}
        </td>
        {!isMobile && <td className={styles.numberColumn} style={{width: '15%'}}>
          {props.cash.balance !== null ? applyRoundingTwoDigits(props.cash.reserved) : '-'}
        </td>}
        {!isMobile && <td className={styles.numberColumn} style={{width: '15%'}}>
          {props.cash.balance !== null ? applyRoundingTwoDigits(props.cash.availableBalance) : '-'}
        </td>}
        <td className={styles.numberColumn} style={{width: '15%'}}>
          {props.cash.balance !== null
            ? applyRoundingTwoDigits(props.cash.availableBalanceBase)
            : '-'
          }
        </td>
        {isMobile && <td className={styles.expandableColumn}>
          <div
            role='button'
            tabIndex={0}
            onClick={toggleExpandButton}
            onKeyDown={toggleExpandButton}
            className={styles.arrow}
          >
            {!isExpanded && <img src={expand} alt='expand arrow' />}
            {isExpanded && <img src={collapse} alt='collapse arrow' />}
          </div>
        </td>}
      </tr>
      {isExpanded && (
        <tr className={styles.border}>
          <td colSpan={5} className={styles.expandedTd}>
            <div className={styles.expandedSection}>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.cash.table.header.fxRate')}
                </p>
                <p className={styles.expandedValue}>
                  {props.cash.balance !== null ? applyRoundingThreeDigits(props.cash.fxRate) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.cash.table.header.availableBalance')}
                </p>
                <p className={styles.expandedValue}>
                  {props.cash.balance !== null ? formatNumber(props.cash.availableBalance) : '-'}
                </p>
              </div>
              <div className={styles.expandedColumn}>
                <p className={styles.expandedHeading}>
                  {t('everise.portfolio.cash.table.header.reserved')}
                </p>
                <p className={styles.expandedValue}>
                  {props.cash.balance !== null ? formatNumber(props.cash.reserved) : '-'}
                </p>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default CashRow;
