import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import User from '../models/User';

type UserContextType = {
  user: User;
  setUser: Dispatch<SetStateAction<any>>;
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<any>>;
  isAuthenticationFailed: boolean;
  setIsAuthenticationFailed: Dispatch<SetStateAction<any>>;
}

const UserContext = createContext<UserContextType>({
  user: {
    id: 0,
    firstName: '',
    name: '',
    registrationCode: '',
    country: '',
    everisePortfolioId: 0, //@TODO: remove this
    defaultPortfolioId: 0,
    portfolios: []
  },
  setUser: () => undefined,
  isLoggedIn: false,
  setIsLoggedIn: () => undefined,
  isAuthenticationFailed: false,
  setIsAuthenticationFailed: () => undefined,
});

export function UserContextProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState({
    id: 0,
    firstName: '',
    name: '',
    registrationCode: '',
    country: '',
    everisePortfolioId: 0, //@TODO: remove this
    defaultPortfolioId: 0,
    portfolios: []
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthenticationFailed, setIsAuthenticationFailed] = useState(false);

  const providerValue = useMemo(() => ({
    user,
    setUser,
    isLoggedIn,
    setIsLoggedIn,
    isAuthenticationFailed,
    setIsAuthenticationFailed,
  }), [user, isLoggedIn, isAuthenticationFailed]);

  return (
    <UserContext.Provider value={providerValue}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;

