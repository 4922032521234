import { ReactNode } from 'react';
import styles from './SectionWrapper.module.css';

type SectionWrapperProps = {
  title: string;
  children: ReactNode
}

function SectionWrapper(props: SectionWrapperProps) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      {props.children}
    </div>
  )
}

export default SectionWrapper;