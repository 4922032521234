import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/config';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { UserContextProvider } from './context/user-context';
import { CustomerContextProvider } from './context/customer-context';
import './assets/fonts/FontFont_FF.Mark.Pro.otf';
import './assets/fonts/FontFont_FF.Mark.Pro.Extra.Light.otf';

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <CustomerContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CustomerContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

